<template>
    <div>
      <div class="studyNews">
        <div class="content">
          <div class="content-left" :style="{ backgroundImage: `url(${ new0.cover_image })`}">
            <p>{{ new0.create_date }}</p>
            <h3 @click="toNews(new0.url)">{{ new0.article_name }}</h3>
            <div class="btn">{{ new0.category[0].name }}</div>
          </div>
          <div class="content-right">
            <div class="content-right-top">
              <div class="content-right-top-left" :style="{ backgroundImage: `url(${ new1.cover_image })`}">
                <p>{{ new1.create_date }}</p>
                <h4 @click="toNews(new1.url)">{{ new1.article_name }}</h4>
                <div class="btn">{{ new1.category[0].name }}</div>
              </div>
              <div class="content-right-top-left" :style="{ backgroundImage: `url(${ new2.cover_image })`}">
                <p>{{ new2.create_date }}</p>
                <h4 @click="toNews(new2.url)">{{ new2.article_name }}</h4>
                <div class="btn">{{ new2.category[0].name }}</div>
              </div>
            </div>
            <div class="content-right-bottom" :style="{ backgroundImage: `url(${ new3.cover_image })`}">
              <p>{{ new3.create_date }}</p>
              <h4 @click="toNews(new3.url)">{{ new3.article_name }}</h4>
              <div class="btn">{{ new3.category[0].name }}</div>
            </div>
          </div>
        </div>
        <div class="classification">
          <div
              @click="toNewList(item.id,item.name)"
              class="things"
              v-for="(item,index) of blogTypes"
              :style="{backgroundImage:'url('+typeImg[index].url+')'}"
              :key="item.id">
            {{ item.name }}
          </div>
        </div>
        <div style="margin: 1.5vw auto" @click="showAll" class="myBtn">查看更多</div>
      </div>

      <showhotspot style="padding-bottom: 10vh"></showhotspot>
<!--      <PolicyChange></PolicyChange>-->
    </div>
</template>

<script>
import showhotspot from "@/components/showhotspot";
import PolicyChange from "@/components/PolicyChange";
import {httpService} from "@/services/http";
import {constants} from "@/services/constants";
    export default {
      components:{
        showhotspot,
        PolicyChange,
      },
      data(){
        return{
          blogs:'',
          blogTypes:'',
          one:'',
          new0:'',
          new1:'',
          new2:'',
          new3:'',
          typeImg:[
            {id:1,url:require("../../assets/myImage/留学信息.jpg")},
            {id:2,url:require("../../assets/myImage/留学生活.jpg")},
            {id:3,url:require("../../assets/myImage/低龄留学.jpg")},
            {id:4,url:require("../../assets/myImage/硕士.jpg")},
            {id:5,url:require("../../assets/myImage/留学知识.jpg")},
            {id:6,url:require("../../assets/myImage/高中出国.jpg")},
            {id:7,url:require("../../assets/myImage/本升硕.jpg")},
            {id:8,url:require("../../assets/myImage/私立院校.jpg")},
            {id:9,url:require("../../assets/myImage/私立院校.jpg")},
          ],

        }
      },
      created() {
        httpService.get(constants.BLOGS).then((data)=>{//获取资讯信息
          this.blogs = data.body.results.slice(4,9)
          this.new0 = this.blogs[0]
          this.new1 = this.blogs[1]
          this.new2 = this.blogs[2]
          this.new3 = this.blogs[4]
          window.sessionStorage.setItem('blogData',JSON.stringify(data.body))
          console.log(this.new0)
        })
        httpService.get(constants.BLOGS_TYPES).then((data) => {//获取资讯类型信息
          this.blogTypes = data.body.slice(0,8)
          console.log(this.blogTypes)
        })
      },

      methods:{
        toNews(url){//打开资讯
          window.open(url,"_blank")
        },

        showAll(){//展示所有资讯类型
          httpService.get(constants.BLOGS_TYPES).then((data) => {
            if (this.blogTypes.length === data.body.length){
              alert('没有更多了哦～～')
            }else {
              this.blogTypes = data.body
            }
          })
        },

        toNewList(id,name){//点击资讯类型跳转到咨询列表页
          this.$router.push({path:'/newslist',query:{id:id,name:name}})
        }
      }
    }
</script>
<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
*{
  padding: 0;
  margin: 0;
}
.classification{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .things{
    height: 13vw;
    width: 18vw;
    margin-top: 1.5vw;
    line-height: 13vw;
    text-align: center;
    color: whitesmoke;
    font-size: 1.5vw;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  //.things:nth-child(1){
  //  background-image: url("../../assets/myImage/留学信息.jpg");
  //}
  //.things:nth-child(2){
  //  background-image: url("../../assets/myImage/留学生活.jpg");
  //}
  //.things:nth-child(3){
  //  background-image: url("../../assets/myImage/低龄留学.jpg");
  //}
  //.things:nth-child(4){
  //  background-image: url("../../assets/myImage/硕士.jpg");
  //}
  //.things:nth-child(5){
  //  background-image: url("../../assets/myImage/留学知识.jpg");
  //}
  //.things:nth-child(6){
  //  background-image: url("../../assets/myImage/高中出国.jpg");
  //}
  //.things:nth-child(7){
  //  background-image: url("../../assets/myImage/本升硕.jpg");
  //}
  //.things:nth-child(8){
  //  background-image: url("../../assets/myImage/私立院校.jpg");
  //}
  //.things:nth-child(9){
  //  background-image: url("../../assets/myImage/热门资讯.jpg");
  //}
}
.studyNews{
  h4:hover{
  text-decoration: underline;
       cursor: pointer;
     }
  h3:hover{
    text-decoration: underline;
    cursor: pointer;
  }
  .btn{
    position: absolute;
    width: 7vw;
    background-color: #007DD1;
    height: 2vw;
    color: white;
    font-size: 13px;
    line-height: 2vw;
    top: 4%;
  }
  width: 80%;
  margin: 2vw auto;
  .content{
    display: flex;
    justify-content: space-between;
    .content-left{
      width: 57.5%;
      height: 37vw;
      //background: url("../../assets/myImage/n1.png");
      background-repeat: no-repeat;
      background-size:cover;  /* 让背景图基于容器大小伸缩 */
      padding-left: 2%;
      position: relative;
      p{
        color: white;
        margin-top: 66%;
        margin-bottom: 2%;
      }
      h3{
        color: ivory;
      }

    }
    .content-right{
      width: 41.5%;
      height: 37vw;
      .content-right-top{
        display: flex;
        justify-content: space-between;
        .content-right-top-left,.content-right-top-right{
          width: 16vw;
          height: 18vw;
          background-size: cover;
          background-repeat: no-repeat;
          padding-left: 5%;
          padding-right: 10%;
          position: relative;
          p{
            color: ivory;
            margin-top: 105%;
            margin-bottom: 5%;
            font-size: 10px;
          }
          h4{
            color: ivory;
          }
          h5{
            color: ivory;
          }
        }
      }
      .content-right-bottom{
        position: relative;
        margin-top: 1vw;
        height: 18vw;
        background-size: cover;
        background-repeat: no-repeat;
        padding-left: 5%;
        p{
          color: white;
          margin-bottom: 2%;
          padding-top: 40%;
          font-size: 10px;
        }
        h4{
          color: white;
        }
      }
    }
  }
}
</style>

