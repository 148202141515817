<template>
  <div class="PolicyChange">
    <div class="content">
      <h3>新加坡政策更动</h3>
      <hr style="color: #393939;background-color:#393939;margin: 1vh 0 2vh">
      <div class="block">
        <el-carousel height="65vh">
          <el-carousel-item v-for="item in 4" :key="item">
            <img width="100%" height="100%" src="../assets/myImage/n1.png" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="newCards">
        <newCard class="card" v-for="item in 9"></newCard>
      </div>
    </div>
  </div>
</template>

<script>
import newCard from "@/components/newCard";
export default {
  name: "PolicyChange",
  components:{
    newCard
  }
}
</script>

<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
//*{
//  padding: 0;
//  margin: 0;
//}
.PolicyChange{
  background: rgba(247, 247, 247, 0.9);
  height: 145vh;
  .content{
    width: 80%;
    margin: 0 auto;
  }
  .newCards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .card{
      margin-top: 3vh;
    }
  }
}
</style>